import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

const ImageContainer = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 1rem;
  width: 100%;
  height: 200px;
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      a: file(relativePath: { eq: "services/custom-orthotics-perth.png" }) {
        childImageSharp {
          fluid(maxHeight: 194) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
    }
  `)
  console.log(data)
  return (
    <Layout>
      <SEO title="Orthotics" />
      <ServicesLayout title="Orthotics">
        <h2>What is plantar fasciitis?</h2>
        <p>
          Orthotics are devices that support your feet through gait preventing
          overpronation-flattening of the arches and stabilising the ankle and
          rearfoot. They can also relive pain and overuse of muscles in the
          lower limb, which in turn can reduce knee, hip and lower back pain.
        </p>
        <h2>Do I need orthotics?</h2>
        <p>Maybe, maybe not.</p>
        <p>
          A thorough biomechanical assessment will determine whether orthotics
          are indicated. If the assessment does not indicate a need for
          orthotics, then there is no point in wasting your money on them. If
          there is an issue, we will try to find a solution - orthotics are only
          one possible solution.
        </p>
        <h2>Custom orthotics vs off-the-shelf?</h2>
        <p>
          If orthotics are deemed beneficial to you, we will first see whether
          off-the-shelf (pre-fabricated) orthotics can be tailored to fit you.
          If so, we will do that (and save you/your health insurance money).
        </p>
        <p>
          If this is not suitable or you prefer custom orthotics, we will scan
          your feet to get the precise measurements necessary to manufacture a
          set of prescription custom orthotics for you.
        </p>
        <p>
          We work closely with Biolab who makes all our custom foot orthotics.
          They are a local West Australian family business that produce truly
          professional orthotics of the best quality.
        </p>
        <ImageContainer>
          <Img
            fluid={data.a.childImageSharp.fluid}
            alt="Custom orthotics Perth"
            style={{
              width: "100%",
              maxWidth: data.a.childImageSharp.fluid.presentationWidth,
            }}
          />
        </ImageContainer>
        <BookButton text="Assess me for orthotics now" size="1.5rem" />
      </ServicesLayout>
    </Layout>
  )
}
